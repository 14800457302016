import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Card, Link, Stack, Fab } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
// redux
import { useDispatch } from '../../../../redux/store';
import { addToCart } from '../../../../redux/slices/product';
// components
import Iconify from '../../../../components/iconify';
import Label from '../../../../components/label';
import Image from '../../../../components/image';
import { ColorPreview } from '../../../../components/color-utils';

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {

  const dispatch = useDispatch();

  const linkTo = PATH_DASHBOARD.eCommerce.view(paramCase(product.sku_name));

  const handleAddCart = async () => {
    const newProduct = {
      id: product.sku_id,
      name :product.sku_name,
      cover : null,
      available: getAvailable(),
      price:getPrice(product.store_transfer_sapcings),
      colors: '',
      storeTransferSapcings:product.store_transfer_sapcings,
      size: '',
      quantity: 1,
      ptr:getPricePtr(product.store_transfer_sapcings),
      packing:getPricePacking(product.store_transfer_sapcings),
      discount:getPricediscount(product.store_transfer_sapcings),
      batchId:getPriceBAtchId(product.store_transfer_sapcings),
      box_id:getPriceBoxId(product.store_transfer_sapcings),
      cgst:getCGST(product.store_transfer_sapcings),
      sgst:getSGST(product.store_transfer_sapcings),
      gst:getGST(product.store_transfer_sapcings)
    };
    try {
      console.log(newProduct);
      dispatch(addToCart(newProduct));
    } catch (error) {
      console.error(error);
    }
  };

  function getPrice(data){
    return data[0]?.batch_detail?.mrp;
    // return pricet;
  }

  function getPricePtr(data){
    return data[0]?.batch_detail?.ptr;
    // return pricet;
  }

  function getPricePacking(data){
    return data[0]?.batch_detail?.packing;
    // return pricet;
  }

  function getPricediscount(data){
    return data[0]?.batch_detail?.margin;
    // return pricet;
  }

  function getPriceBAtchId(data){
    return data[0]?.batch_detail?.batchId;
    // return pricet;
  }

  function getPriceBoxId(data){
    return data[0]?.box?.box_id;
  }

  function getCGST(data){
    return data[0]?.batch_detail?.cgst;
    // return pricet;
  }

  function getSGST(data){
    return data[0]?.batch_detail?.sgst;
    // return pricet;
  }

  function getGST(data){
    return data[0]?.batch_detail?.gst;
    // return pricet;
  }
  

  function getAvailable(){
    let available = 0;
    if(product.soldQuantity){
      available = product.totalQuantity - product.soldQuantity;
    }else{
      available = product.totalQuantity;
    }
    return available;
  }

  return (
    
    <Card
      sx={{
        '&:hover .add-cart-btn': {
          opacity: 1,
        },backgroundColor:"#b3e6ff",mb:3
      }}
    >
      <Box sx={{ position: 'relative', p: 1 }}>

        <Fab
          color="warning"
          size="medium"
          className="add-cart-btn"
          onClick={handleAddCart}
          sx={{
            right: 16,
            top: 60,
            zIndex: 9,
            opacity: 0,
            position: 'absolute',
            transition: (theme) =>
              theme.transitions.create('all', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter,
              }),
          }}
        >
          <Iconify icon="ic:round-add-shopping-cart" />
        </Fab>

        { /* <Image alt={name} src={cover} ratio="1/1" sx={{ borderRadius: 1.5 }} /> */ }
      </Box>

      <Stack spacing={5} sx={{ p: 1 }}>
        <Link to={linkTo} component={RouterLink} color="inherit" variant="subtitle2" noWrap>
          {product.sku_name && product.sku_name}
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">

          <Stack direction="row" spacing={0.5} sx={{ typography: 'subtitle1' }}>
            {/* {priceSale && (
              <Box component="span" sx={{ color: 'text.disabled', textDecoration: 'line-through' }}>
                {fCurrency(getPrice(product.store_transfer_details))}
              </Box>
            )} */}
            <Box component="span">{fCurrency(getPrice(product?.store_transfer_sapcings))}</Box>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
