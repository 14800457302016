import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import AxiosMain from '../../utils/axios_main';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  orders: [],
  order: null,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORGANIZATION CATEGORIES
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // GET ORGANIZATION CATEGORY
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getOrders(storeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/order_details/ordersByStoreId/${storeId}`);
      console.log(response.data);
      dispatch(slice.actions.getOrdersSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrder(orderId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/order_details/orderByOrderId/${orderId}`);
      dispatch(slice.actions.getOrderSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}