// ----------------------------------------------------------------------

export const company = [
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS',
  'Zillion POS'
];
