import PropTypes from 'prop-types';
// @mui
import { Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useAuthContext } from '../../auth/useAuthContext';
import axios from '../../utils/axios';
import AxiosMain from '../../utils/axios_main';
import { useSnackbar } from '../snackbar';


// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  query: PropTypes.string,
  sx: PropTypes.object,
};

export default function SearchNotFound({ query, sx, ...other }) {
  const {user} = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {

    if(query.length >3)
    {const bouncedata ={
      StoreId : user.storeid,
      StaffId : user.staffid,
      BounceListName : query,
      OrganizationId : user.organization_id,
      CreatedBy : user.id
     }
     const CreateBounceList =   AxiosMain.post('bouncelists/add_bouncelist',bouncedata);
     enqueueSnackbar('Bounce List Added' );
      console.log(CreateBounceList);
      console.log(user.staffid);}
   
  }, [query])
  return query ? (
    
    <Paper
      sx={{
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" paragraph>
        Not found
      </Typography>

      <Typography variant="body2">
        No results found for &nbsp;
        <strong>&quot;{query}&quot;</strong>.
        <br /> Try checking for typos or using complete words.
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2" sx={sx}>
      Please enter keywords
    </Typography>
  );
}
