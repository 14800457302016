import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice, getType } from '@reduxjs/toolkit';
import get from 'lodash/get';


// utils
import axios from '../../utils/axios';
import AxiosMain from '../../utils/axios_main';
//
import { dispatch } from '../store';


import PRODUCT_1 from '../../pages/dashboard/product_images/PRODUCT_1.jfif';
import PRODUCT_2 from '../../pages/dashboard/product_images/PRODUCT_2.jfif';
import PRODUCT_3 from '../../pages/dashboard/product_images/PRODUCT_3.jpg';
import PRODUCT_4 from '../../pages/dashboard/product_images/PRODUCT_4.jfif';
import PRODUCT_5 from '../../pages/dashboard/product_images/PRODUCT_5.jfif';
import PRODUCT_6 from '../../pages/dashboard/product_images/PRODUCT_6.jfif';
import PRODUCT_7 from '../../pages/dashboard/product_images/PRODUCT_7.jfif';
import PRODUCT_8 from '../../pages/dashboard/product_images/PRODUCT_8.jfif';
import PRODUCT_9 from '../../pages/dashboard/product_images/PRODUCT_9.jfif';
import PRODUCT_10 from '../../pages/dashboard/product_images/PRODUCT_10.jfif';
import PRODUCT_11 from '../../pages/dashboard/product_images/PRODUCT_11.jfif';
import PRODUCT_12 from '../../pages/dashboard/product_images/PRODUCT_12.jfif';
import PRODUCT_13 from '../../pages/dashboard/product_images/PRODUCT_13.jfif';
import PRODUCT_14 from '../../pages/dashboard/product_images/PRODUCT_14.jfif';
import PRODUCT_15 from '../../pages/dashboard/product_images/PRODUCT_15.jfif';
import PRODUCT_16 from '../../pages/dashboard/product_images/PRODUCT_16.jfif';
import PRODUCT_17 from '../../pages/dashboard/product_images/PRODUCT_17.jfif';
import PRODUCT_18 from '../../pages/dashboard/product_images/PRODUCT_18.jfif';
import PRODUCT_19 from '../../pages/dashboard/product_images/PRODUCT_19.jfif';
import PRODUCT_20 from '../../pages/dashboard/product_images/PRODUCT_20.jfif';
import PRODUCT_21 from '../../pages/dashboard/product_images/PRODUCT_21.jfif';
import PRODUCT_22 from '../../pages/dashboard/product_images/PRODUCT_22.jfif';
import PRODUCT_23 from '../../pages/dashboard/product_images/PRODUCT_23.jfif';
import PRODUCT_24 from '../../pages/dashboard/product_images/PRODUCT_24.jfif';
// secondary images
import SUBPROD_1 from '../../pages/dashboard/subprod_images/SUBPROD_1.jfif';
import SUBPROD_2 from '../../pages/dashboard/subprod_images/SUBPROD_2.jfif';
import SUBPROD_3 from '../../pages/dashboard/subprod_images/SUBPROD_3.jfif';
import SUBPROD_4 from '../../pages/dashboard/subprod_images/SUBPROD_4.jpg';
import SUBPROD_5 from '../../pages/dashboard/subprod_images/SUBPROD_5.jfif';
import SUBPROD_6 from '../../pages/dashboard/subprod_images/SUBPROD_6.jfif';
import SUBPROD_7 from '../../pages/dashboard/subprod_images/SUBPROD_7.jfif';
import SUBPROD_8 from '../../pages/dashboard/subprod_images/SUBPROD_8.jfif';
import SUBPROD_9 from '../../pages/dashboard/subprod_images/SUBPROD_9.jfif';
import SUBPROD_10 from '../../pages/dashboard/subprod_images/SUBPROD_10.jfif';
import SUBPROD_11 from '../../pages/dashboard/subprod_images/SUBPROD_11.jfif';
import SUBPROD_12 from '../../pages/dashboard/subprod_images/SUBPROD_12.jfif';
import SUBPROD_13 from '../../pages/dashboard/subprod_images/SUBPROD_13.jfif';
import SUBPROD_14 from '../../pages/dashboard/subprod_images/SUBPROD_14.jfif';
import SUBPROD_15 from '../../pages/dashboard/subprod_images/SUBPROD_15.jfif';
import SUBPROD_16 from '../../pages/dashboard/subprod_images/SUBPROD_16.jfif';
import SUBPROD_17 from '../../pages/dashboard/subprod_images/SUBPROD_17.jfif';
import SUBPROD_18 from '../../pages/dashboard/subprod_images/SUBPROD_18.jfif';
import SUBPROD_19 from '../../pages/dashboard/subprod_images/SUBPROD_19.jfif';
import SUBPROD_20 from '../../pages/dashboard/subprod_images/SUBPROD_20.jfif';
import SUBPROD_21 from '../../pages/dashboard/subprod_images/SUBPROD_21.jfif';
import SUBPROD_22 from '../../pages/dashboard/subprod_images/SUBPROD_22.jfif';
import SUBPROD_23 from '../../pages/dashboard/subprod_images/SUBPROD_23.jfif';
import SUBPROD_24 from '../../pages/dashboard/subprod_images/SUBPROD_24.jfif';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  productBatchDetails: [],
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    totalItems: 0,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    getBatchDetailsBySkuIdStoreIDSuccess(state, action) {
      state.isLoading = false;
      state.productBatchDetails = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const totalItems = sum(cart.map((product) => product.quantity));
      const subtotal = sum(cart.map((product) => product.price * product.quantity));
      const cartDiscount = sum(cart.map((product) => ((product.price * product.quantity)/100)*(product.discount)));
      const gst = sum(cart.map((product) => ((product.price * product.quantity)/100)*(product.gst)));
      state.checkout.cart = cart;
      state.checkout.discount = (state.checkout.discount) || 0;
      state.checkout.shipping = state.checkout.shipping || 0;
      state.checkout.billing = state.checkout.billing || null;
      state.checkout.subtotal = subtotal.toFixed(2);
      state.checkout.storeId = 1;
      state.checkout.organizationId = 1;
      state.checkout.hubId = 1;
      state.checkout.createdBy = 1;
      state.checkout.cartDiscount = cartDiscount.toFixed(2);
      state.checkout.gst = gst.toFixed(2);
      state.checkout.total = ((subtotal - state.checkout.discount - cartDiscount + gst).toFixed(2));
      state.checkout.totalItems = totalItems;
    },

    addToCart(state, action) {
      const newProduct = action.payload;
      const isEmptyCart = !state.checkout.cart.length;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, newProduct];
      } else {
        state.checkout.cart = state.checkout.cart.map((product) => {
          const isExisted = product.id === newProduct.id;

          if (isExisted) {
            return {
              ...product,
              colors: uniq([...product.colors, ...newProduct.colors]),
              quantity: product.quantity + 1,
            };
          }

          return product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, newProduct], 'id');
      state.checkout.totalItems = sum(state.checkout.cart.map((product) => product.quantity));
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((product) => product.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    backStep(state) {
      state.checkout.activeStep -= 1;
    },

    nextStep(state) {
      state.checkout.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.checkout.activeStep = step;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;

      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },
  },
});

const dataForSingleProduct =
  [
    {
      "Abilify": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
        "cover": PRODUCT_1,
        "images": [
          PRODUCT_1,
          SUBPROD_1
        ],
        "name": "Abilify",
        "code": "38BEE270",
        "sku": "WW75K5210YW/SV",
        "tags": [
          "It works by changing the actions of chemicals in the brain",
          "Abilify is an antipsychotic medication."
        ],
        "price": 16.19,
        "priceSale": 16.19,
        "totalRating": 2.5,
        "totalReview": 2038,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 1932,
            "reviewCount": 4891
          },
          {
            "name": "2 Star",
            "starCount": 8862,
            "reviewCount": 819
          },
          {
            "name": "3 Star",
            "starCount": 6815,
            "reviewCount": 1488
          },
          {
            "name": "4 Star",
            "starCount": 4961,
            "reviewCount": 6242
          },
          {
            "name": "5 Star",
            "starCount": 4418,
            "reviewCount": 9332
          }
        ],
        "reviews": [],
        "status": "sale",
        "inventoryType": "low_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 24,
        "description": "\n<p>Abilify is an antipsychotic medication. It works by changing the actions of chemicals in the brain.</p>\n",
        "sold": 351,
        "createdAt": "2022-11-26T18:08:39.900Z",
        "category": "Tablets",
        "gender": "All",
        "colors": [
          "#00AB55",
          "#000000"
        ]
      },
    },
    {
      "Abiraterone": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
        "cover": PRODUCT_2,
        "images": [
          PRODUCT_2,
          SUBPROD_2
        ],
        "name": "Abiraterone",
        "code": "38BEE271",
        "sku": "WW75K5211YW/SV",
        "tags": [
          "oral tablet (125 mg; 250 mg; 500 mg)",
          "to treat prostate cancer "
        ],
        "price": 35.71,
        "priceSale": null,
        "totalRating": 2,
        "totalReview": 2209,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 3438,
            "reviewCount": 8148
          },
          {
            "name": "2 Star",
            "starCount": 4536,
            "reviewCount": 7345
          },
          {
            "name": "3 Star",
            "starCount": 9642,
            "reviewCount": 7720
          },
          {
            "name": "4 Star",
            "starCount": 6671,
            "reviewCount": 8688
          },
          {
            "name": "5 Star",
            "starCount": 3256,
            "reviewCount": 9597
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "out_of_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Abiraterone is used together with steroid medication (prednisone or methylprednisolone) to treat prostate cancer that has spread to other parts of the body. abiraterone is used in men whose prostate cancer cannot be treated with surgery or other medicines.</p>\n",
        "sold": 548,
        "createdAt": "2022-11-25T17:08:39.900Z",
        "category": "Medicines",
        "gender": "Male",
        "colors": [
          "#000000",
          "#FFFFFF"
        ]
      }
    },
    {
      "AzaSite": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
        "cover": PRODUCT_3,
        "images": [
          PRODUCT_3,
          SUBPROD_3
        ],
        "name": "AzaSite",
        "code": "38BEE272",
        "sku": "WW75K5212YW/SV",
        "tags": [
          "eye infections",
          "antibiotic that fights bacteria"
        ],
        "price": 34.3,
        "priceSale": null,
        "totalRating": 4.9,
        "totalReview": 3332,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 5210,
            "reviewCount": 5138
          },
          {
            "name": "2 Star",
            "starCount": 737,
            "reviewCount": 3755
          },
          {
            "name": "3 Star",
            "starCount": 631,
            "reviewCount": 2797
          },
          {
            "name": "4 Star",
            "starCount": 9705,
            "reviewCount": 731
          },
          {
            "name": "5 Star",
            "starCount": 9616,
            "reviewCount": 4068
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "low_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>AzaSite is an eye drop containing azithromycin, a macrolide antibiotic that fights bacteria.</p>\n",
        "sold": 854,
        "createdAt": "2022-11-24T16:08:39.900Z",
        "category": "Medicines",
        "gender": "Men",
        "colors": [
          "#FFFFFF",
          "#FFC0CB"
        ]
      }
    },
    {
      "Cabometyx": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
        "cover": PRODUCT_4,
        "images": [
          PRODUCT_4,
          SUBPROD_4
        ],
        "name": "Cabometyx",
        "code": "38BEE273",
        "sku": "WW75K5213YW/SV",
        "tags": [
          "inhibitor that blocks the action of certain protein kinases.",
          "Cabometyx is a kinase inhibitor"
        ],
        "price": 93.1,
        "priceSale": 93.1,
        "totalRating": 2,
        "totalReview": 7579,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 343,
            "reviewCount": 9755
          },
          {
            "name": "2 Star",
            "starCount": 1892,
            "reviewCount": 3404
          },
          {
            "name": "3 Star",
            "starCount": 9716,
            "reviewCount": 8642
          },
          {
            "name": "4 Star",
            "starCount": 2269,
            "reviewCount": 4320
          },
          {
            "name": "5 Star",
            "starCount": 9384,
            "reviewCount": 772
          }
        ],
        "reviews": [],
        "status": "sale",
        "inventoryType": "low_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 71,
        "description": "\n<p>Cabometyx is used to treat liver cancer (hepatocellular carcinoma) in people who have been previously treated with sorafenib.</p>\n",
        "sold": 871,
        "createdAt": "2022-11-23T15:08:39.900Z",
        "category": "Medicines",
        "gender": "All",
        "colors": [
          "#FFC0CB",
          "#FF4842",
          "#1890FF"
        ]
      }
    },
    {
      "Calquence": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
        "cover": PRODUCT_5,
        "images": [
          PRODUCT_5,
          SUBPROD_5
        ],
        "name": "Calquence",
        "code": "38BEE274",
        "sku": "WW75K5214YW/SV",
        "tags": [
          "interfere with the growth and spread of cancer cells",
          "treat mantle cell lymphoma"
        ],
        "price": 55.47,
        "priceSale": null,
        "totalRating": 4,
        "totalReview": 3073,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 2926,
            "reviewCount": 4280
          },
          {
            "name": "2 Star",
            "starCount": 3744,
            "reviewCount": 5557
          },
          {
            "name": "3 Star",
            "starCount": 2840,
            "reviewCount": 2616
          },
          {
            "name": "4 Star",
            "starCount": 6990,
            "reviewCount": 2876
          },
          {
            "name": "5 Star",
            "starCount": 3906,
            "reviewCount": 5709
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "in_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Calquence is also used to treat chronic lymphocytic leukemia (CLL) or small lymphocytic lymphoma (SLL) in adults.</p>\n",
        "sold": 820,
        "createdAt": "2022-11-22T14:08:39.900Z",
        "category": "Medicines",
        "gender": "Men",
        "colors": [
          "#FF4842",
          "#1890FF"
        ]
      }
    },
    {
      "Cephalexinx": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
        "cover": PRODUCT_6,
        "images": [
          PRODUCT_6,
          SUBPROD_6
        ],
        "name": "Cephalexinx",
        "code": "38BEE275",
        "sku": "WW75K5215YW/SV",
        "tags": [
          "treat infections caused by bacteria",
          "bone infection"
        ],
        "price": 89.09,
        "priceSale": null,
        "totalRating": 5,
        "totalReview": 9864,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 6214,
            "reviewCount": 2812
          },
          {
            "name": "2 Star",
            "starCount": 9740,
            "reviewCount": 2514
          },
          {
            "name": "3 Star",
            "starCount": 8043,
            "reviewCount": 5100
          },
          {
            "name": "4 Star",
            "starCount": 2234,
            "reviewCount": 460
          },
          {
            "name": "5 Star",
            "starCount": 619,
            "reviewCount": 3887
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "in_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Cephalexin is used to treat infections caused by bacteria, including upper respiratory infections, ear infections, skin infections, urinary tract infections and bone infections.</p>\n",
        "sold": 937,
        "createdAt": "2022-11-21T13:08:39.900Z",
        "category": "Medicines",
        "gender": "Men",
        "colors": [
          "#1890FF"
        ]
      }
    },
    {
      "Pravastatin": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
        "cover": PRODUCT_7,
        "images": [
          PRODUCT_7,
          SUBPROD_7
        ],
        "name": "Pravastatin",
        "code": "38BEE276",
        "sku": "WW75K5216YW/SV",
        "tags": [
          "to increase levels of good cholesterol",
          "to lower triglycerides"
        ],
        "price": 44.39,
        "priceSale": 44.39,
        "totalRating": 4.9,
        "totalReview": 8217,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 8756,
            "reviewCount": 459
          },
          {
            "name": "2 Star",
            "starCount": 8506,
            "reviewCount": 8905
          },
          {
            "name": "3 Star",
            "starCount": 8850,
            "reviewCount": 1580
          },
          {
            "name": "4 Star",
            "starCount": 6991,
            "reviewCount": 5145
          },
          {
            "name": "5 Star",
            "starCount": 2451,
            "reviewCount": 7198
          }
        ],
        "reviews": [],
        "status": "sale",
        "inventoryType": "out_of_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 92,
        "description": "\n<p>Pravastatin is also used to lower the risk of stroke, heart attack, and other heart complications in people with or without coronary heart disease or other risk factors.</p>\n",
        "sold": 911,
        "createdAt": "2022-11-20T12:08:39.900Z",
        "category": "Medicines",
        "gender": "ALL",
        "colors": [
          "#00AB55",
          "#000000"
        ]
      }
    },
    {
      "Prednisolone": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
        "cover": PRODUCT_8,
        "images": [
          PRODUCT_8,
          SUBPROD_8
        ],
        "name": "Prednisolone",
        "code": "38BEE277",
        "sku": "WW75K5217YW/SV",
        "tags": [
          "arthritis, lupus, psoriasis, ulcerative colitis",
          "conditions that affect the skin, eyes, lungs, stomach, nervous system, or blood cells"
        ],
        "price": 26.92,
        "priceSale": null,
        "totalRating": 5,
        "totalReview": 7682,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 7374,
            "reviewCount": 7906
          },
          {
            "name": "2 Star",
            "starCount": 5219,
            "reviewCount": 74
          },
          {
            "name": "3 Star",
            "starCount": 4573,
            "reviewCount": 4403
          },
          {
            "name": "4 Star",
            "starCount": 7977,
            "reviewCount": 4151
          },
          {
            "name": "5 Star",
            "starCount": 8183,
            "reviewCount": 3179
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "in_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Prednisolone is used to treat many different inflammatory conditions such as arthritis, lupus, psoriasis, ulcerative colitis, allergic disorders, gland (endocrine) disorders, and conditions that affect the skin, eyes, lungs, stomach, nervous system, or blood cells.</p>\n",
        "sold": 170,
        "createdAt": "2022-11-19T11:08:39.900Z",
        "category": "Medicines",
        "gender": "ALL",
        "colors": [
          "#FF4842",
          "#1890FF"
        ]
      }
    },
    {
      "Paracetamol": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9",
        "cover": PRODUCT_9,
        "images": [
          PRODUCT_9,
          SUBPROD_9
        ],
        "name": "Paracetamol",
        "code": "38BEE278",
        "sku": "WW75K5218YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 45.35,
        "priceSale": null,
        "totalRating": 3.7,
        "totalReview": 762,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 5985,
            "reviewCount": 9498
          },
          {
            "name": "2 Star",
            "starCount": 2674,
            "reviewCount": 3202
          },
          {
            "name": "3 Star",
            "starCount": 4069,
            "reviewCount": 2807
          },
          {
            "name": "4 Star",
            "starCount": 6435,
            "reviewCount": 3749
          },
          {
            "name": "5 Star",
            "starCount": 1479,
            "reviewCount": 1168
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "low_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>The evidence suggests that paracetamol inhibits the production of prostaglandins, which are made by the body to deal with illness and injury. It's also thought to act on the serotonergic, opioid, nitric oxide and cannabinoid pathways.</p>\n",
        "sold": 544,
        "createdAt": "2022-11-18T10:08:39.900Z",
        "category": "Medicines",
        "gender": "Women",
        "colors": [
          "#FFFFFF",
          "#FFC0CB"
        ]
      }
    },
    {
      "Pentasa": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10",
        "cover": PRODUCT_10,
        "images": [
          PRODUCT_10,
          SUBPROD_10
        ],
        "name": "Pentasa",
        "code": "38BEE279",
        "sku": "WW75K5219YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 26.96,
        "priceSale": 26.96,
        "totalRating": 2.5,
        "totalReview": 9582,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 6934,
            "reviewCount": 567
          },
          {
            "name": "2 Star",
            "starCount": 1757,
            "reviewCount": 3655
          },
          {
            "name": "3 Star",
            "starCount": 6634,
            "reviewCount": 4478
          },
          {
            "name": "4 Star",
            "starCount": 9284,
            "reviewCount": 6776
          },
          {
            "name": "5 Star",
            "starCount": 1237,
            "reviewCount": 929
          }
        ],
        "reviews": [],
        "status": "sale",
        "inventoryType": "in_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 75,
        "description": "\n<p>Pentasa is used to treat mild to moderate ulcerative colitis in adults. Ucerative colitis is associated with inflammation, ulcers and sores in the bowel causing bleeding, stomach pain, and diarrhoea.</p>\n",
        "sold": 976,
        "createdAt": "2022-11-17T09:08:39.900Z",
        "category": "Medicines",
        "gender": "ALL",
        "colors": [
          "#FFFFFF",
          "#FFC0CB",
          "#FF4842",
          "#1890FF"
        ]
      }
    },
    {
      "Tabloid": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11",
        "cover": PRODUCT_11,
        "images": [
          PRODUCT_11,
          SUBPROD_11
        ],
        "name": "Tabloid",
        "code": "38BEE2710",
        "sku": "WW75K52110YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 78.22,
        "priceSale": null,
        "totalRating": 2,
        "totalReview": 1056,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 3885,
            "reviewCount": 3471
          },
          {
            "name": "2 Star",
            "starCount": 2608,
            "reviewCount": 8977
          },
          {
            "name": "3 Star",
            "starCount": 9832,
            "reviewCount": 1878
          },
          {
            "name": "4 Star",
            "starCount": 6414,
            "reviewCount": 352
          },
          {
            "name": "5 Star",
            "starCount": 1082,
            "reviewCount": 9632
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "out_of_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Tabloid is used to treat acute nonlymphocytic leukemia (a type of blood cancer), sometimes in combination with other cancer medicines.</p>\n",
        "sold": 51,
        "createdAt": "2022-11-16T08:08:39.900Z",
        "category": "Medicines",
        "gender": "ALL",
        "colors": [
          "#FFC0CB",
          "#FF4842",
          "#1890FF"
        ]
      }
    },
    {
      "Tapentadol": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b12",
        "cover": PRODUCT_12,
        "images": [
          PRODUCT_12,
          SUBPROD_12
        ],
        "name": "Tapentadol",
        "code": "38BEE2711",
        "sku": "WW75K52111YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 35.54,
        "priceSale": null,
        "totalRating": 4.9,
        "totalReview": 5189,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 5979,
            "reviewCount": 4945
          },
          {
            "name": "2 Star",
            "starCount": 7739,
            "reviewCount": 443
          },
          {
            "name": "3 Star",
            "starCount": 3404,
            "reviewCount": 5287
          },
          {
            "name": "4 Star",
            "starCount": 2006,
            "reviewCount": 2028
          },
          {
            "name": "5 Star",
            "starCount": 668,
            "reviewCount": 5781
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "low_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Tapentadol is a prescription medicine that is used to treat moderate to severe pain.</p>\n",
        "sold": 11,
        "createdAt": "2022-11-15T07:08:39.901Z",
        "category": "Medicines",
        "gender": "ALL",
        "colors": [
          "#FFFFFF",
          "#FFC0CB",
          "#FF4842",
          "#1890FF"
        ]
      }
    },
    {
      "Taclonex": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13",
        "cover": PRODUCT_13,
        "images": [
          PRODUCT_13,
          SUBPROD_13
        ],
        "name": "Taclonex",
        "code": "38BEE2712",
        "sku": "WW75K52112YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 90.69,
        "priceSale": 90.69,
        "totalRating": 4.8,
        "totalReview": 3300,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 3788,
            "reviewCount": 9240
          },
          {
            "name": "2 Star",
            "starCount": 3933,
            "reviewCount": 7348
          },
          {
            "name": "3 Star",
            "starCount": 4832,
            "reviewCount": 4236
          },
          {
            "name": "4 Star",
            "starCount": 1539,
            "reviewCount": 1322
          },
          {
            "name": "5 Star",
            "starCount": 3244,
            "reviewCount": 3234
          }
        ],
        "reviews": [],
        "status": "sale",
        "inventoryType": "in_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 85,
        "description": "\n<p>Taclonex Ointment is a prescription medicine that is for use on the skin only. The ointment is used to treat psoriasis vulgaris in adults 18 years of age and older. It should be used only on the skin and only by adults.</p>\n",
        "sold": 700,
        "createdAt": "2022-11-14T06:08:39.901Z",
        "category": "Medicines",
        "gender": "Men",
        "colors": [
          "#FFFFFF",
          "#FFC0CB",
          "#FF4842",
          "#1890FF",
          "#94D82D"
        ]
      }
    },
    {
      "Tarceva": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b14",
        "cover": PRODUCT_14,
        "images": [
          PRODUCT_14,
          SUBPROD_14
        ],
        "name": "Tarceva",
        "code": "38BEE2713",
        "sku": "WW75K52113YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 63.61,
        "priceSale": null,
        "totalRating": 4,
        "totalReview": 8950,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 8517,
            "reviewCount": 817
          },
          {
            "name": "2 Star",
            "starCount": 4580,
            "reviewCount": 135
          },
          {
            "name": "3 Star",
            "starCount": 8221,
            "reviewCount": 3278
          },
          {
            "name": "4 Star",
            "starCount": 2972,
            "reviewCount": 7613
          },
          {
            "name": "5 Star",
            "starCount": 907,
            "reviewCount": 5158
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "in_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Tarceva is a cancer medicine that interferes with the growth of cancer cells and slows their spread in the body.</p>\n",
        "sold": 679,
        "createdAt": "2022-11-13T05:08:39.901Z",
        "category": "Medicines",
        "gender": "ALL",
        "colors": [
          "#FF4842",
          "#1890FF",
          "#94D82D"
        ]
      }
    },
    {
      "Habitrol": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b15",
        "cover": PRODUCT_15,
        "images": [
          PRODUCT_15,
          SUBPROD_15
        ],
        "name": "Habitrol",
        "code": "38BEE2714",
        "sku": "WW75K52114YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 67.55,
        "priceSale": null,
        "totalRating": 2,
        "totalReview": 2174,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 4492,
            "reviewCount": 2069
          },
          {
            "name": "2 Star",
            "starCount": 4237,
            "reviewCount": 763
          },
          {
            "name": "3 Star",
            "starCount": 7774,
            "reviewCount": 3894
          },
          {
            "name": "4 Star",
            "starCount": 9496,
            "reviewCount": 8207
          },
          {
            "name": "5 Star",
            "starCount": 8307,
            "reviewCount": 2730
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "out_of_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>This replaces the nicotine you would get from smoking and makes the withdrawal effects from not smoking less severe. The amount of nicotine is decreased over time until use is stopped.</p>\n",
        "sold": 361,
        "createdAt": "2022-11-12T04:08:39.901Z",
        "category": "Medicines",
        "gender": "Men",
        "colors": [
          "#00AB55",
          "#000000"
        ]
      }
    },
    {
      "Halog": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b16",
        "cover": PRODUCT_16,
        "images": [
          PRODUCT_16,
          SUBPROD_16
        ],
        "name": "Halog Ointment",
        "code": "38BEE2715",
        "sku": "WW75K52115YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 94.75,
        "priceSale": 94.75,
        "totalRating": 3.7,
        "totalReview": 243,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 7276,
            "reviewCount": 6237
          },
          {
            "name": "2 Star",
            "starCount": 5686,
            "reviewCount": 447
          },
          {
            "name": "3 Star",
            "starCount": 2221,
            "reviewCount": 4207
          },
          {
            "name": "4 Star",
            "starCount": 3700,
            "reviewCount": 490
          },
          {
            "name": "5 Star",
            "starCount": 375,
            "reviewCount": 8339
          }
        ],
        "reviews": [],
        "status": "sale",
        "inventoryType": "low_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 64,
        "description": "\n<p>Halcinonide topical is used to help relieve redness, itching, swelling, or other discomfort caused by skin conditions. This medicine is a corticosteroid (cortisone-like medicine or steroid).</p>\n",
        "sold": 83,
        "createdAt": "2022-11-11T03:08:39.901Z",
        "category": "Medicines",
        "gender": "Women",
        "colors": [
          "#1890FF",
          "#94D82D",
          "#FFC107"
        ]
      }
    },
    {
      "Haldol": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17",
        "cover": PRODUCT_17,
        "images": [
          PRODUCT_17,
          SUBPROD_17
        ],
        "name": "Haldol",
        "code": "38BEE2716",
        "sku": "WW75K52116YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 75.78,
        "priceSale": null,
        "totalRating": 1.4,
        "totalReview": 4347,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 3520,
            "reviewCount": 559
          },
          {
            "name": "2 Star",
            "starCount": 1355,
            "reviewCount": 2646
          },
          {
            "name": "3 Star",
            "starCount": 5474,
            "reviewCount": 8232
          },
          {
            "name": "4 Star",
            "starCount": 5234,
            "reviewCount": 2633
          },
          {
            "name": "5 Star",
            "starCount": 9573,
            "reviewCount": 1859
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "out_of_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Haldol is sometimes used in people who are unable to take the medicine by mouth.</p>\n",
        "sold": 687,
        "createdAt": "2022-11-10T02:08:39.901Z",
        "category": "Medicines",
        "gender": "ALL",
        "colors": [
          "#FF4842",
          "#1890FF"
        ]
      }
    },
    {
      "Diflucan": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b18",
        "cover": PRODUCT_18,
        "images": [
          PRODUCT_18,
          SUBPROD_18
        ],
        "name": "Diflucan",
        "code": "38BEE2717",
        "sku": "WW75K52117YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 39.6,
        "priceSale": null,
        "totalRating": 2.4,
        "totalReview": 9211,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 7540,
            "reviewCount": 8
          },
          {
            "name": "2 Star",
            "starCount": 4594,
            "reviewCount": 1980
          },
          {
            "name": "3 Star",
            "starCount": 3048,
            "reviewCount": 5296
          },
          {
            "name": "4 Star",
            "starCount": 1936,
            "reviewCount": 3320
          },
          {
            "name": "5 Star",
            "starCount": 4739,
            "reviewCount": 8883
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "out_of_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Diflucan is used to treat infections caused by fungus, which can invade any part of the body including the mouth, throat, esophagus, lungs, bladder, genital area, and the blood.</p>\n",
        "sold": 618,
        "createdAt": "2022-11-09T01:08:39.901Z",
        "category": "Medicines",
        "gender": "ALL",
        "colors": [
          "#1890FF"
        ]
      }
    },
    {
      "Delzicol": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b19",
        "cover": PRODUCT_19,
        "images": [
          PRODUCT_19,
          SUBPROD_19
        ],
        "name": "Delzicol",
        "code": "38BEE2718",
        "sku": "WW75K52118YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 52.84,
        "priceSale": 52.84,
        "totalRating": 1.8,
        "totalReview": 4068,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 3514,
            "reviewCount": 4539
          },
          {
            "name": "2 Star",
            "starCount": 8167,
            "reviewCount": 8251
          },
          {
            "name": "3 Star",
            "starCount": 6500,
            "reviewCount": 5857
          },
          {
            "name": "4 Star",
            "starCount": 8609,
            "reviewCount": 5683
          },
          {
            "name": "5 Star",
            "starCount": 3959,
            "reviewCount": 3598
          }
        ],
        "reviews": [],
        "status": "sale",
        "inventoryType": "low_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 71,
        "description": "\n<p>Delzicol reduces the inflammation caused by ulcerative colitis. It's thought its anti-inflammatory action comes from its ability to block the enzyme cyclooxygenase (COX) and inhibit prostaglandin production in the colon. COX and prostaglandins (a group of lipids) are both involved in generating an inflammatory response.</p>\n",
        "sold": 451,
        "createdAt": "2022-11-08T00:08:39.901Z",
        "category": "Medicines",
        "gender": "Women",
        "colors": [
          "#00AB55",
          "#000000"
        ]
      }
    },
    {
      "Demerol": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b20",
        "cover": PRODUCT_20,
        "images": [
          PRODUCT_20,
          SUBPROD_20
        ],
        "name": "Demerol",
        "code": "38BEE2719",
        "sku": "WW75K52119YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 72.8,
        "priceSale": null,
        "totalRating": 5,
        "totalReview": 3789,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 3342,
            "reviewCount": 6145
          },
          {
            "name": "2 Star",
            "starCount": 2713,
            "reviewCount": 8071
          },
          {
            "name": "3 Star",
            "starCount": 6608,
            "reviewCount": 4859
          },
          {
            "name": "4 Star",
            "starCount": 2469,
            "reviewCount": 9139
          },
          {
            "name": "5 Star",
            "starCount": 6024,
            "reviewCount": 4600
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "in_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Demerol is a strong prescription pain medicine that is used to manage the relief short-term pain, when other pain treatments such as non-opioid pain medicines do not treat your pain well enough or you cannot tolerate them.</p>\n",
        "sold": 911,
        "createdAt": "2022-11-06T23:08:39.901Z",
        "category": "Medicines",
        "gender": "Men",
        "colors": [
          "#FF4842",
          "#1890FF"
        ]
      }
    },
    {
      "Diovan": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b21",
        "cover": PRODUCT_21,
        "images": [
          PRODUCT_21,
          SUBPROD_21
        ],
        "name": "Diovan",
        "code": "38BEE2720",
        "sku": "WW75K52120YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 83.08,
        "priceSale": null,
        "totalRating": 2.9,
        "totalReview": 4590,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 3739,
            "reviewCount": 2913
          },
          {
            "name": "2 Star",
            "starCount": 3374,
            "reviewCount": 108
          },
          {
            "name": "3 Star",
            "starCount": 8237,
            "reviewCount": 6085
          },
          {
            "name": "4 Star",
            "starCount": 2346,
            "reviewCount": 9422
          },
          {
            "name": "5 Star",
            "starCount": 9174,
            "reviewCount": 9096
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "low_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Diovan is an angiotensin II receptor blocker (sometimes called an ARB). Valsartan keeps blood vessels from narrowing, which lowers blood pressure and improves blood flow.</p>\n",
        "sold": 180,
        "createdAt": "2022-11-05T22:08:39.901Z",
        "category": "Medicines",
        "gender": "Women",
        "colors": [
          "#FF4842",
          "#1890FF"
        ]
      }
    },
    {
      "Doxazosin": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b22",
        "cover": PRODUCT_22,
        "images": [
          PRODUCT_22,
          SUBPROD_22
        ],
        "name": "Doxazosin",
        "code": "38BEE2721",
        "sku": "WW75K52121YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 85.02,
        "priceSale": 85.02,
        "totalRating": 3.9,
        "totalReview": 1897,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 1960,
            "reviewCount": 5204
          },
          {
            "name": "2 Star",
            "starCount": 1022,
            "reviewCount": 642
          },
          {
            "name": "3 Star",
            "starCount": 4885,
            "reviewCount": 2050
          },
          {
            "name": "4 Star",
            "starCount": 6462,
            "reviewCount": 622
          },
          {
            "name": "5 Star",
            "starCount": 7738,
            "reviewCount": 7920
          }
        ],
        "reviews": [],
        "status": "sale",
        "inventoryType": "in_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 94,
        "description": "\n<p>Doxazosin is used to treat hypertension (high blood pressure), or to improve urination in men with benign prostatic hyperplasia (enlarged prostate).</p>\n",
        "sold": 630,
        "createdAt": "2022-11-04T21:08:39.901Z",
        "category": "Medicines",
        "gender": "ALL",
        "colors": [
          "#1890FF"
        ]
      }
    },
    {
      "Sabril": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b23",
        "cover": PRODUCT_23,
        "images": [
          PRODUCT_23,
          SUBPROD_23
        ],
        "name": "Sabril",
        "code": "38BEE2722",
        "sku": "WW75K52122YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 69.22,
        "priceSale": null,
        "totalRating": 3.9,
        "totalReview": 497,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 332,
            "reviewCount": 44
          },
          {
            "name": "2 Star",
            "starCount": 4745,
            "reviewCount": 7357
          },
          {
            "name": "3 Star",
            "starCount": 810,
            "reviewCount": 7106
          },
          {
            "name": "4 Star",
            "starCount": 2409,
            "reviewCount": 869
          },
          {
            "name": "5 Star",
            "starCount": 4377,
            "reviewCount": 5440
          }
        ],
        "reviews": [],
        "status": "new",
        "inventoryType": "in_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Sabril is used in combination with other medications to treat complex partial seizures in adults and children who are at least 10 years old. The powder for oral solution is used to treat infantile spasms in babies and children between the ages of 1 month and 2 years.</p>\n",
        "sold": 479,
        "createdAt": "2022-11-03T20:08:39.901Z",
        "category": "Medicines",
        "gender": "Men",
        "colors": [
          "#1890FF",
          "#94D82D",
          "#FFC107"
        ]
      }
    },
    {
      "Saizen": {
        "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b24",
        "cover": PRODUCT_24,
        "images": [
          PRODUCT_24,
          SUBPROD_24
        ],
        "name": "Saizen",
        "code": "38BEE2723",
        "sku": "WW75K52123YW/SV",
        "tags": [
          "Approved by CDSCO",
          "Medicine"
        ],
        "price": 60.96,
        "priceSale": null,
        "totalRating": 1.8,
        "totalReview": 3595,
        "ratings": [
          {
            "name": "1 Star",
            "starCount": 1655,
            "reviewCount": 5723
          },
          {
            "name": "2 Star",
            "starCount": 9585,
            "reviewCount": 19
          },
          {
            "name": "3 Star",
            "starCount": 10,
            "reviewCount": 4144
          },
          {
            "name": "4 Star",
            "starCount": 9173,
            "reviewCount": 4551
          },
          {
            "name": "5 Star",
            "starCount": 1459,
            "reviewCount": 9214
          }
        ],
        "reviews": [],
        "status": "",
        "inventoryType": "low_stock",
        "sizes": [
          "6",
          "7",
          "8",
          "8.5",
          "9",
          "9.5",
          "10",
          "10.5",
          "11",
          "11.5",
          "12",
          "13"
        ],
        "available": 2,
        "description": "\n<p>Saizen is used to treat growth failure in children and adults who lack natural growth hormone. This includes people with short stature at birth with no catch-up growth, and other causes.</p>\n",
        "sold": 190,
        "createdAt": "2022-11-02T19:08:39.901Z",
        "category": "Medicines",
        "gender": "Kids",
        "colors": [
          "#FF4842",
          "#1890FF"
        ]
      }
    }
  ];


// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addToCart,
  resetCart,
  gotoStep,
  backStep,
  nextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(storeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/medicines/getSkuAlldataBystoreId/${storeId}`);
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products/product', {
      //   params: { name },
      // });

      const response = await dataForSingleProduct.forEach((element) => {

        if (get(element, name)) {
          dispatch(slice.actions.getProductSuccess(get(element, name)));
        }

      });

      // console.log(name);
      // console.log(typeof (name));

      // dataForSingleProduct.map()



      // dataForSingleProduct.forEach((element) => {
      //   const vl = "Saizen";

      //   console.log(element.vl);



      //   // if (element.filterName) {
      //   //   console.log(element.filterName);
      //   //   // 
      //   // }

      // }
      // );




    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getBatchDetailsBySkuIdStoreID(skuId, StoreID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await AxiosMain.get(`/medicines/getBatchDetailsBySkuIdStoreId/${StoreID}/${skuId}`);
      dispatch(slice.actions.getBatchDetailsBySkuIdStoreIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
